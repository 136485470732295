import { createSlice } from '@reduxjs/toolkit';

import { deleteProject, duplicateProject, editProject, getProjectsPreview } from './actions';

import { LabelResponse, Preview } from 'modules/label/types';

import { resetStoreAction } from 'core/actions';
import { mergeByProp } from 'modules/common/utils';

const name = 'projects';

type InitialState = {
  loading: boolean;
  projects: Preview[];
  total: number;
  page: number;
  error?: string;
};

const initialState: InitialState = {
  loading: false,
  projects: [],
  total: 0,
  page: 0,
  error: undefined,
};

const convertData = ({ id, preview, properties }: LabelResponse): Preview => {
  return {
    id,
    preview,
    properties: {
      labelTitle: properties.labelTitle,
      width: properties.width,
      height: properties.height,
      unit: properties.unit,
    },
  };
};

const projectsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearProjects(state) {
      state.projects = [];
      state.page = 0;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all projects preview
    builder.addCase(getProjectsPreview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectsPreview.fulfilled, (state, { payload }) => {
      const { items, total } = payload;

      const newProjects = [...state.projects, ...items];

      // getting unique list
      const filteredProjectsById = mergeByProp(newProjects, 'id');

      state.projects = filteredProjectsById;
      state.total = total;
      state.page = state.page + 1;
      state.loading = false;
    });
    builder.addCase(getProjectsPreview.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Duplicate project
    builder.addCase(duplicateProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(duplicateProject.fulfilled, (state, { payload }) => {
      state.loading = false;

      const label = convertData(payload);
      state.projects = [label, ...state.projects];
    });
    builder.addCase(duplicateProject.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Delete project
    builder.addCase(deleteProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProject.fulfilled, (state, { payload }) => {
      state.projects = state.projects.filter((label) => label.id !== payload);
      state.loading = false;
    });
    builder.addCase(deleteProject.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Edit project
    builder.addCase(editProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editProject.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editProject.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

export const { clearProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
