import { combineReducers } from '@reduxjs/toolkit';

import authReducer from '../modules/auth/reducer';
import graphicsReducer from '../modules/label/reducers/graphics.reducer';
import iconsReducer from '../modules/label/reducers/icons.reducer';
import imagesReducer from '../modules/label/reducers/images.reducer';
import labelReducer from '../modules/label/reducers/label.reducer';
import listLabelReducer from '../modules/label/reducers/listLabel.reducer';
import shapesReducer from '../modules/label/reducers/shapes.reducer';
import labelTemplatesReducer from '../modules/label/reducers/templates.reducer';
import profileReducer from '../modules/profile/profile.reducer';
import projectsReducer from '../modules/projects/projects.reducer';
import templatesReducer from '../modules/templates/templates.reducer';
import rootReducer from './rootReducer';

import graphicsManageReducer from 'modules/manage/graphics/graphics.reducer';
import iconsManageReducer from 'modules/manage/icons/icons.reducer';
import shapesManageReducer from 'modules/manage/shapes/shapes.reducer';
import templatesManageReducer from 'modules/manage/templates/templates.reducer';

const allReducers = {
  root: rootReducer,
  auth: authReducer,
  shapes: shapesReducer,
  icons: iconsReducer,
  graphics: graphicsReducer,
  images: imagesReducer,
  labelTemplates: labelTemplatesReducer,
  listLabel: listLabelReducer,
  label: labelReducer,
  templates: templatesReducer,
  profile: profileReducer,
  projects: projectsReducer,
  manage: combineReducers({
    shape: shapesManageReducer,
    icon: iconsManageReducer,
    graphic: graphicsManageReducer,
    template: templatesManageReducer,
  }),
};

export default allReducers;
