import { cloneElement, ReactElement } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { PageLayout } from '../page-layout';

import { Modules } from 'core/types';

import { ThemeProvider } from 'modules/common/theme-provider';

export const BaseLayout = ({ routes }: Partial<Modules>) => (
  <ThemeProvider>
    <PageLayout>
      <Router>
        <Switch>{routes ? routes.map((comp: ReactElement) => cloneElement(comp)) : null}</Switch>
      </Router>
    </PageLayout>
  </ThemeProvider>
);
