import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createLabel,
  createTemplate,
  deleteLabel,
  getLabel,
  getTemplate,
  updateLabel,
} from '../actions';
import { convertCanvasItemDotsToPx } from '../utils/oldDataConverter';

import { TSizeWithUnit } from 'core/types';
import { ILabel } from 'modules/label/types';
import { ITemplate } from 'modules/templates/types';

import { resetStoreAction } from 'core/actions';
import { Units } from 'core/constants';

const defaultCanvas: ILabel = {
  form: [],
  sizeInUnit: { width: 0, height: 0, unit: Units.Inches },
  isCircle: false,
  previewId: '',
  previewColor: 'rgb(255, 255, 255)',
  labelTitle: 'Label Title',
};

interface InitialState {
  activeLabelId?: string | null;
  data: fabric.Object[];
  labelLoading: boolean;
  labelDeleting: boolean;
  saving: boolean;
  savingTemplate: boolean;
  error?: string | null;
  sizeInUnit: TSizeWithUnit;
  circle: boolean;
  labelTitle: string;
  previewColor: string;
  previewId: string;
}

const initialState: InitialState = {
  activeLabelId: null,
  data: defaultCanvas.form,
  labelLoading: false,
  labelDeleting: false,
  saving: false,
  savingTemplate: false,
  error: null,
  sizeInUnit: defaultCanvas.sizeInUnit,
  previewColor: defaultCanvas.previewColor,
  previewId: defaultCanvas.previewId,
  labelTitle: defaultCanvas.labelTitle,
  circle: defaultCanvas.isCircle,
};

const rootReducer = createSlice({
  name: 'label',
  initialState,
  reducers: {
    setActiveLabelId(state, action: PayloadAction<string>) {
      state.activeLabelId = action.payload;
    },
    updateCanvasSizeInUnit(state, action: PayloadAction<TSizeWithUnit>) {
      const { payload } = action;
      state.sizeInUnit = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearState(state: any) {
      for (const key in state) {
        const k = key as keyof InitialState;
        state[k] = initialState[k];
      }
    },
    changeLabelTitle(state, action: PayloadAction<string>) {
      state.labelTitle = action.payload;
    },
    newLabel(state, action: PayloadAction<(TSizeWithUnit & { circle: boolean }) | undefined>) {
      if (action.payload) {
        const { width, height, unit, circle } = action.payload;
        state.sizeInUnit = { width, height, unit };
        state.circle = circle;
      } else {
        state.sizeInUnit = { width: 6, height: 4, unit: Units.Inches };
      }
    },
    setLabel(state, action: PayloadAction<ITemplate>) {
      const { sizeInUnit, circle, previewColor, labelTitle } = action.payload.properties;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.data = action.payload.data as fabric.Object[];
      state.sizeInUnit = sizeInUnit;
      state.previewColor = previewColor;
      state.labelTitle = labelTitle;
      state.labelLoading = false;
      state.circle = circle ?? false;
    },
    changePreviewColor(state, action: PayloadAction<string>) {
      const { payload } = action;

      state.previewColor = payload;
    },
    changeSize(state, action: PayloadAction<TSizeWithUnit>) {
      const { payload } = action;

      state.sizeInUnit = payload;
    },
    changeShapeOfCanvas(state, action: PayloadAction<boolean>) {
      state.circle = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Create label
    builder.addCase(createLabel.pending, (state) => {
      state.labelLoading = true;
    });
    builder.addCase(createLabel.fulfilled, (state, { payload }) => {
      state.labelLoading = false;
      state.activeLabelId = payload.id;
    });
    builder.addCase(createLabel.rejected, (state, action) => {
      state.labelLoading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Get label
    builder.addCase(getLabel.pending, (state) => {
      state.labelLoading = true;
    });
    builder.addCase(getLabel.fulfilled, (state, { payload }) => {
      const { id, form, sizeInUnit, isCircle, previewColor, previewId, labelTitle, old } = payload;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.data = old ? convertCanvasItemDotsToPx(form, sizeInUnit) : form;
      state.sizeInUnit = sizeInUnit;
      state.previewColor = previewColor;
      state.labelTitle = labelTitle;
      state.labelLoading = false;
      state.circle = isCircle ?? false;
      state.previewId = previewId;
      state.activeLabelId = id;
    });
    builder.addCase(getLabel.rejected, (state, action) => {
      state.labelLoading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Get template
    builder.addCase(getTemplate.pending, (state) => {
      state.labelLoading = true;
    });
    builder.addCase(getTemplate.fulfilled, (state, action) => {
      const { form, properties } = action.payload;

      const { circle, unit, height, width, previewColor } = properties;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.data = JSON.parse(form) as fabric.Object[];
      state.sizeInUnit = {
        unit: unit as Units,
        height: height as number,
        width: width as number,
      };
      state.previewColor = previewColor ?? 'rgb(255, 255, 255)';
      state.labelTitle = 'Label Title';
      state.labelLoading = false;
      state.circle = circle ?? false;
    });
    builder.addCase(getTemplate.rejected, (state, action) => {
      state.labelLoading = false;

      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Update label
    builder.addCase(updateLabel.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(updateLabel.fulfilled, (state, { payload }) => {
      state.activeLabelId = payload.id;
      state.saving = false;
    });
    builder.addCase(updateLabel.rejected, (state, action) => {
      state.saving = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Delete label
    builder.addCase(deleteLabel.pending, (state) => {
      state.labelDeleting = true;
    });
    builder.addCase(deleteLabel.fulfilled, (state) => {
      state.labelDeleting = false;
    });
    builder.addCase(deleteLabel.rejected, (state) => {
      state.labelDeleting = false;
    });

    // Convert label to template
    builder.addCase(createTemplate.pending, (state) => {
      state.savingTemplate = true;
    });
    builder.addCase(createTemplate.fulfilled, (state) => {
      state.savingTemplate = false;
    });
    builder.addCase(createTemplate.rejected, (state, action) => {
      state.savingTemplate = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

export const {
  setActiveLabelId,
  updateCanvasSizeInUnit,
  clearState,
  setLabel,
  newLabel,
  changePreviewColor,
  changeSize,
  changeShapeOfCanvas,
  changeLabelTitle,
} = rootReducer.actions;
export default rootReducer.reducer;
