import { Suspense, useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { Authenticator } from 'aws-amplify-react';
import { isMobile } from 'react-device-detect';
import { Provider } from 'react-redux';
import { store } from 'core';

import { BaseLayout } from 'components/layout/base-layout';
import { Preloader } from 'components/preloader';

import { Modules } from 'core/types';

import { getGoogleFonts } from 'core/actions';
import { config } from 'core/services/Auth';
import { getUserInfo } from 'modules/auth/actions';
import {
  ConfirmationWasSent,
  ConfirmSignIn,
  ForgotPassword,
  MobilePreview,
  SignIn,
  SignUp,
} from 'modules/auth/containers';

Auth.configure(config);

const handleUserSubscription = ({ payload }: HubCapsule) => {
  if (payload.event === 'updateUserAttributes') {
    store.dispatch(getUserInfo());
  }
};

const AppComponent = ({ routes }: Partial<Modules>) => {
  useEffect(() => {
    store.dispatch(getUserInfo());
    store.dispatch(getGoogleFonts());
  }, []);

  useEffect(() => {
    Hub.listen('auth', handleUserSubscription);
    return () => {
      Hub.remove('auth', handleUserSubscription);
    };
  }, []);

  return (
    <Provider store={store}>
      <Suspense fallback={<Preloader />}>
        <BaseLayout routes={routes} />
      </Suspense>
    </Provider>
  );
};

export const App = (props: Modules) => {
  const urlParams = new URLSearchParams(window.location.search);
  const webViewType = urlParams.get('webview');
  const authPage = urlParams.get('authPage');

  const isSkipPreview = webViewType === 'mobile' && authPage === 'signin';

  const [authState, setAuthState] = useState<string | null>();

  const handleAuthStateChange = (state: string) => {
    setAuthState(state);
  };

  if (authState === 'signedIn') {
    return <AppComponent {...props} />;
  }

  return (
    <Authenticator
      hideDefault={true}
      authState={isMobile && !isSkipPreview ? 'mobilePreview' : 'signIn'}
      onStateChange={handleAuthStateChange}>
      <MobilePreview key="mobilePreview" />
      <SignIn key="signIn" />
      <SignUp key="signUp" />
      <ConfirmationWasSent key="confirmSignUp" />
      <ForgotPassword key="forgotPassword" />
      <ConfirmSignIn key="confirmSignIn" />
    </Authenticator>
  );
};
