import { createSlice } from '@reduxjs/toolkit';

import {
  deleteBulkTemplates,
  deleteTemplate,
  editBulkTemplateTags,
  editTemplateTags,
  getTemplates,
} from './actions';

import { PageInfo } from '../types';
import { Content } from 'modules/common/types';

import { resetStoreAction } from 'core/actions';

const name = 'manage/templates';

type InitialState = {
  templates: Content[];
  pageInfo: PageInfo;
  loading: boolean;
  error?: string;
};

const initialState: InitialState = {
  templates: [],
  pageInfo: {
    offset: 20,
    total: 0,
  },
  loading: false,
  error: undefined,
};

const templatesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all templates
    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      const { items, ...pageInfo } = action.payload;

      const templates = items.map((item) => {
        const parsedForm = JSON.parse(item.form);
        return {
          ...item,
          form: parsedForm.preview,
          properties: {
            ...item.properties,
            'mime-type': item.properties['mime-type'] || 'image/png',
            name: parsedForm.labelTitle || item.properties.name || 'label title',
          },
        };
      });

      state.templates = templates;
      state.pageInfo = pageInfo;
      state.loading = false;
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Edit template tags
    builder.addCase(editTemplateTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editTemplateTags.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editTemplateTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Edit bulk icon tags
    builder.addCase(editBulkTemplateTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editBulkTemplateTags.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editBulkTemplateTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Delete shape
    builder.addCase(deleteTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTemplate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Bulk delete shapes
    builder.addCase(deleteBulkTemplates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkTemplates.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBulkTemplates.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
  },
});

export default templatesSlice.reducer;
