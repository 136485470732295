import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { deleteImage, getImages } from '../actions';

import { Image } from '../types';

import { resetStoreAction } from 'core/actions';
import { mergeByProp } from 'modules/common/utils';

const name = 'label/images';

type InitialState = {
  data: Image[];
  loading: boolean;
  error?: string | null;
  total?: number;
  page: number;
};

const initialState: InitialState = {
  data: [],
  loading: false,
  error: null,
  total: undefined,
  page: 0,
};

const imagesSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearImages(state) {
      state.data = [];
      state.page = 0;
    },
    addImage(state, action: PayloadAction<Image>) {
      if (state.data) {
        state.data = [action.payload, ...state.data];
      }
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all images
    builder.addCase(getImages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImages.fulfilled, (state, { payload }) => {
      const { items, total } = payload;

      const newItems = [...state.data, ...items];

      // getting unique images list
      const filteredItemsById = mergeByProp(newItems, 'id');
      state.data = filteredItemsById;

      state.total = total;
      state.page = state.page + 1;
      state.loading = false;
    });
    builder.addCase(getImages.rejected, (state, action) => {
      state.error = action.payload?.errorMessage;
      state.data = [];
      state.loading = false;
    });

    // Delate image
    builder.addCase(deleteImage.fulfilled, (state, { payload }) => {
      if (state.data) {
        state.data = state.data.filter((image) => image.id !== payload);
        state.page = state.page ? state.page - 1 : 0;
      }
    });
  },
});

export const { addImage, clearImages } = imagesSlice.actions;
export default imagesSlice.reducer;
