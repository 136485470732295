import { forwardRef } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import Box from '@mui/material/Box';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { Controller, FieldErrors, UseControllerProps } from 'react-hook-form';

import { styled } from '@mui/material/styles';

const styles = {
  container: {
    width: '100%',
  },
  error: { color: 'error.main', fontSize: 14, mt: '5px' },
};

type Props = StandardTextFieldProps & {
  errors?: FieldErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
};

type CommonInputProps = UseControllerProps & Props;

export const TextInputBase = styled(TextField)({
  '& .MuiInputBase-input': {
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    padding: '0.5rem 0',
  },
});

// eslint-disable-next-line react/display-name
export const TextInput = forwardRef<HTMLInputElement, CommonInputProps>(
  ({ name, control, errors, className, ...props }, ref) => {
    return (
      <Box sx={styles.container}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextInputBase
              fullWidth
              {...field}
              {...props}
              inputRef={ref}
              autoCorrect="off"
              className={className}
              error={!!errors?.[name]}
            />
          )}
        />
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message, messages }) => {
              return messages ? (
                Object.entries(messages).map(([type, message]) => (
                  <Box key={type} sx={styles.error}>
                    {message}
                  </Box>
                ))
              ) : (
                <Box sx={styles.error}>{message}</Box>
              );
            }}
          />
        )}
      </Box>
    );
  }
);
