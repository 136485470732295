import MUIButton, { ButtonProps } from '@mui/material/Button';

import { styled } from '@mui/material/styles';

export const Button = styled(MUIButton)<ButtonProps>(() => ({
  fontSize: 16,
  textTransform: 'none',
  fontFamily: 'Nunito Sans, sans-serif',
  borderRadius: '24px!important',
  fontWeight: 700,
  '&:disabled': {
    opacity: 0.3,
  },
}));
