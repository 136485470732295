import { createSlice } from '@reduxjs/toolkit';

import { deleteLabel, duplicateLabel, getLabelsPreview } from '../actions';

import { LabelResponse, Preview } from '../types';

import { resetStoreAction } from 'core/actions';
import { mergeByProp } from 'modules/common/utils';

const name = 'listLabel';

export interface IListLabelStore {
  loading: boolean;
  labels: Preview[];
  total: number;
  page: number;
  error?: string | null;
}

const convertData = ({ id, preview, properties }: LabelResponse): Preview => {
  return {
    id,
    preview,
    properties: {
      labelTitle: properties.labelTitle,
      width: properties.width,
      height: properties.height,
      unit: properties.unit,
    },
  };
};

const initialState: IListLabelStore = {
  loading: false,
  labels: [],
  error: null,
  total: 0,
  page: 0,
};

const listLabel = createSlice({
  name,
  initialState,
  reducers: {
    clearLabels(state) {
      state.labels = [];
      state.page = 0;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all labels preview
    builder.addCase(getLabelsPreview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLabelsPreview.fulfilled, (state, { payload }) => {
      const { items, total } = payload;

      const newLabels = [...state.labels, ...items];

      // getting unique list
      const filteredLabelsById = mergeByProp(newLabels, 'id');

      state.labels = filteredLabelsById;
      state.total = total;
      state.page = state.page + 1;
      state.loading = false;
    });
    builder.addCase(getLabelsPreview.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Duplicate label
    builder.addCase(duplicateLabel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(duplicateLabel.fulfilled, (state, { payload }) => {
      state.loading = false;

      const label = convertData(payload);
      state.labels = [label, ...state.labels];
    });
    builder.addCase(duplicateLabel.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Delete label
    builder.addCase(deleteLabel.fulfilled, (state, { payload }) => {
      state.labels = state.labels.filter((label) => label.id !== payload);
    });
  },
});

export const { clearLabels } = listLabel.actions;
export default listLabel.reducer;
