import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'core';

import labelAPI from './api.service';

import {
  GetImagesParams,
  GetLabelsParams,
  ILabel,
  ImagesListResponse,
  LabelActionProps,
  LabelResponse,
  LabelsListPreviewResponse,
  UpdateLabelActionProps,
} from './types';
import {
  Content,
  PublicContent,
  PublicContentsParams,
  PublicContentsType,
  PublicContentTags,
} from 'modules/common/types';

import { Units } from 'core/constants';
import publicContentAPI from 'modules/common/api.services/public-content.api.service';
import managePublicContentAPI from 'modules/manage/api.service';

interface IErrors {
  errorMessage: string;
}

export const createLabel = createAsyncThunk<
  // Return type of the payload creator
  LabelResponse,
  // First argument to the payload creator
  LabelActionProps,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('project/create', async ({ canvasData, preview, properties }, thunkApi) => {
  try {
    const response = await labelAPI.createLabel(canvasData, properties, preview);

    return response.data;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const duplicateLabel = createAsyncThunk<
  // Return type of the payload creator
  LabelResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  { rejectValue: IErrors; state: RootState }
>('project/duplicate', async (payload, thunkApi) => {
  try {
    const response = await labelAPI.duplicateLabel(payload);

    return response.data;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const updateLabel = createAsyncThunk<
  // Return type of the payload creator
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // First argument to the payload creator
  UpdateLabelActionProps,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('project/update', async ({ id, canvasData, preview, previewId, properties }, thunkApi) => {
  try {
    const response = await labelAPI.updateLabel(id, canvasData, properties, preview, previewId);
    return {
      id: response.data.id,
    };
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

// TODO: Old API was replaced by getLabelsPreview (remove if no longer needed)
// can be use in the 'All projects page' in the future

// export const getLabels = createAsyncThunk<
//   // Return type of the payload creator
//   LabelsListResponse,
//   // First argument to the payload creator
//   GetLabelsParams | undefined,
//   // Types for ThunkAPI
//   {
//     rejectValue: IErrors;
//   }
// >('projects/get', async (payload, thunkApi) => {
//   try {
//     const response = await labelAPI.getLabels({
//       size: 10,
//       ...payload,
//     });

//     return response.data;
//   } catch (err) {
//     const error = err as AxiosError<IErrors>; // cast the error for access
//     if (!error.response) {
//       throw err;
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//     return thunkApi.rejectWithValue(error.response.data);
//   }
// });

export const getLabelsPreview = createAsyncThunk<
  // Return type of the payload creator
  LabelsListPreviewResponse,
  // First argument to the payload creator
  GetLabelsParams | undefined,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('projects/get', async (payload, thunkApi) => {
  try {
    const response = await labelAPI.getLabelsPreview({
      size: 10,
      ...payload,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getLabel = createAsyncThunk<
  // Return type of the payload creator
  ILabel,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('label/get', async (id, thunkApi) => {
  try {
    const {
      data: { form, properties, previewId },
    } = await labelAPI.getLabel(id);

    const data: ILabel = {
      id,
      form: JSON.parse(form) as fabric.Object[],
      previewId,
      isCircle: properties.circle,
      previewColor: properties.previewColor,
      sizeInUnit: {
        unit: properties.unit as Units,
        width: properties.width,
        height: properties.height,
      },
      labelTitle: properties.labelTitle,
      old: properties.old,
    };

    return data;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteLabel = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('label/delete', async (id, thunkApi) => {
  try {
    await labelAPI.deleteLabel(id);

    return id;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getShapes = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('label/shapes', async (payload, thunkApi) => {
  try {
    const response = await publicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Shape,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getIcons = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('manage/icons', async (payload, thunkApi) => {
  try {
    const response = await publicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Icon,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getGraphics = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('label/graphics', async (payload, thunkApi) => {
  try {
    const response = await publicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Graphic,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getTemplates = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('label/templates', async (payload, thunkApi) => {
  try {
    const response = await publicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Template,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getTemplate = createAsyncThunk<
  Content,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('label/template', async (payload, thunkApi) => {
  try {
    const response = await publicContentAPI.getPublicContent({
      ...payload,
      contentType: PublicContentsType.Template,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getTemplateTags = createAsyncThunk<PublicContentTags, void, { rejectValue: IErrors }>(
  'label/templates/tags',
  async (_arg, thunkApi) => {
    try {
      const response = await publicContentAPI.getTags(PublicContentsType.Template);

      return response.data[0];
    } catch (err) {
      const error = err as AxiosError<IErrors>;
      if (!error.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const getImages = createAsyncThunk<
  ImagesListResponse,
  GetImagesParams,
  { rejectValue: IErrors }
>('images', async (payload, thunkApi) => {
  try {
    const response = await labelAPI.getImages(payload);
    return response.data;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteImage = createAsyncThunk<string, string, { rejectValue: IErrors }>(
  'images/delete',
  async (id, thunkApi) => {
    try {
      await labelAPI.deleteImage(id);
      return id;
    } catch (err) {
      const error: AxiosError<IErrors> = err as AxiosError<IErrors>;
      if (!error.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const createTemplate = createAsyncThunk<
  void,
  { template: Omit<Content, 'id'>; onSuccess: () => void },
  { rejectValue: IErrors }
>('project/convert/to/template', async ({ template, onSuccess }, thunkApi) => {
  try {
    await managePublicContentAPI.createPublicContents(template);
    onSuccess();
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});
