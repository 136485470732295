import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Preloader } from 'components/preloader';
import { routePaths } from '../constants';
import { useCurrentUser } from '../hooks/use-current-user';

export const AuthRoute = (props: RouteProps) => {
  const { currentUser, loading } = useCurrentUser();

  if (!currentUser?.email && !loading) {
    return <Redirect to={routePaths.root} />;
  }

  if (loading) {
    return <Preloader />;
  }

  return <Route {...props} />;
};
