import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#E7EAF6',
      main: '#6C13F9',
      dark: '#5915e8',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#969BB2',
      dark: '#e0e0e0',
      contrastText: '#7335F6',
    },
    text: {
      primary: '#1C0022',
      secondary: '#969BB2',
    },
  },
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
  },
});

const globalStyles = {
  body: {
    fontFamily: 'Nunito Sans, sans-serif',
    margin: 0,
  },
  input: {
    ' -webkit-user-select': 'text',
    userSelect: 'text',
  },
  textarea: {
    ' -webkit-user-select': 'text',
    userSelect: 'text',
  },
  '::-webkit-scrollbar': {
    height: '7px',
    width: '0px',
    background: '#c7cff9',
    borderRadius: '3.5px',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#6c13f9',
    borderRadius: '3.5px',
  },
  '*': {
    scrollbarColor: '#6c13f9 #c7cff9',
  },
};

type Props = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: Props) => {
  return (
    <MUIThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <Box
        id="no-print-wrapper"
        sx={{
          displayPrint: 'none',
          overflowY: {
            xs: 'hidden',
            sm: 'auto',
          },
        }}>
        {children}
      </Box>
    </MUIThemeProvider>
  );
};
