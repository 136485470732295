import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import profileAPI from './api.service';

import { IErrors } from 'core/types';

export const deleteUser = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  { onSuccess: () => void },
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('label/delete', async ({ onSuccess }, thunkApi) => {
  try {
    await profileAPI.deleteUser();
    onSuccess();

    return;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});
