import { httpClient, removeEmptyValues } from 'core';

import {
  Content,
  PublicContent,
  PublicContentsParams,
  PublicContentsType,
  PublicContentTags,
} from 'modules/common/types';

const dataSerializer = (params: PublicContentsParams) => {
  const requestParams = {
    ...params,
    name: params.name?.trim(),
    tags: params.tags
      ?.split(',')
      .map((tag) => tag.trim())
      .join(','),
  };

  const serializedParams = removeEmptyValues(requestParams);

  return new URLSearchParams(serializedParams).toString();
};

const getPublicContents = async (
  params: PublicContentsParams
): Promise<{
  data: PublicContent;
}> => {
  return await httpClient.get('/public-contents', {
    params,
    paramsSerializer: dataSerializer,
  });
};

const getPublicContent = async (
  params: PublicContentsParams
): Promise<{
  data: Content;
}> => {
  const { id, ...restParams } = params;
  return await httpClient.get(`/public-contents/${id}`, {
    params: restParams,
    paramsSerializer: dataSerializer,
  });
};

const getTags = async (
  contentType: PublicContentsType
): Promise<{
  data: PublicContentTags[];
}> => {
  return await httpClient.get('/public-contents/tags', {
    params: {
      contentType,
    },
  });
};

export default {
  getPublicContent,
  getPublicContents,
  getTags,
};
