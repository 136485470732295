import startCase from 'lodash/startCase';
import { getCanvasSize } from 'core';

import { canvasDotsHeight, canvasDotsWidth } from '../constants';

import { TSizeWithUnit } from 'core/types';
import { TPosition } from 'modules/label/types';

import { canvasSizeInPxWeb } from 'core/constants';

export const round = (num: number, fraction = 2) =>
  Math.round(num * 10 * fraction) / (10 * fraction);

export const convertPxToDots = (px: number, pxInDots: number): number => round(px / pxInDots);

export const convertDotsToPx = (dots: number, pxInDots: number): number => round(dots * pxInDots);

const isNumber = (value: unknown): value is number => typeof value === 'number';

export const convertPath = (
  path: Array<Array<string | number>>,
  convertFunction: (num: number, pxInDots: number) => number,
  pxInDots: number
) => {
  return path.map((arr: Array<string | number>) => {
    return arr.map((item: string | number) => {
      return isNumber(item) ? convertFunction(item, pxInDots) : item;
    });
  });
};

export const convertCanvasItem = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any,
  convertFunction: (num: number, pxInDots: number) => number,
  pxInDots: TPosition
) => {
  const path: Array<Array<string | number>> = item.path as Array<Array<string | number>>;

  return {
    path: path ? convertPath(path, convertFunction, pxInDots.y) : null,
    pathOffset: item.pathOffset
      ? {
          x: convertFunction(item.pathOffset.x, pxInDots.x),
          y: convertFunction(item.pathOffset.y, pxInDots.y),
        }
      : null,
    type: item.type,
    minWidth: item.type === 'text' ? 20 : null,
    left: convertFunction(item.left, pxInDots.x),
    top: convertFunction(item.top, pxInDots.y),
    width:
      item.type === 'image' || item.type === 'line'
        ? item.width
        : convertFunction(item.width, pxInDots.x),
    height:
      item.type === 'image' || item.type === 'line'
        ? item.height
        : convertFunction(item.height, pxInDots.y),
    scaleX: item.type !== 'image' ? item.scaleX : convertFunction(item.scaleX, pxInDots.x),
    scaleY: item.type !== 'image' ? item.scaleY : convertFunction(item.scaleY, pxInDots.y),
    ...(item.fontFamily && { fontFamily: startCase(item.fontFamily) }),
  };
};

export const convertCanvasItemDotsToPx = (
  data: fabric.Object[],
  sizeInUnit: TSizeWithUnit
): fabric.Object[] => {
  const size = getCanvasSize(canvasSizeInPxWeb, sizeInUnit);

  const pxInDots = {
    x: size.width / canvasDotsWidth,
    y: size.height / canvasDotsHeight,
  };

  const convertedData = data.map((item) => {
    return {
      ...item,
      ...convertCanvasItem(item, convertDotsToPx, pxInDots),
    };
  });

  return convertedData as unknown as fabric.Object[];
};
