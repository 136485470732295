import { Units } from 'core/constants';

export enum PublicContentsType {
  Shape = 'shape',
  Icon = 'icon',
  Graphic = 'graphic',
  Template = 'template',
}

export type Content = {
  id: string;
  form: string;
  updatedAt?: number;
  tags: string[];
  contentType?: PublicContentsType;
  preview?: string;
  properties: {
    name: string;
    contentType: PublicContentsType;
    'mime-type': string;
    circle?: boolean;
    preview?: string;
    width?: number;
    height?: number;
    unit?: Units;
    previewColor?: string;
  };
};

export interface PublicContent {
  items: Content[] | [];
  offset: number;
  total: number;
}

export type PublicContentsParams = {
  contentType?: PublicContentsType;
  id?: string;
  tags?: string;
  name?: string;
  file?: File;
  page?: number;
  size?: number;
};

export type PageInfo = {
  offset: number;
  total: number;
};

export type PublicContentTags = {
  tags: string[];
  contentType: PublicContentsType;
};
