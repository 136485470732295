import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import projectAPI, { EditProjectParams } from './api.service';

import { IErrors } from 'core/types';
import { GetLabelsParams, LabelResponse, LabelsListPreviewResponse } from 'modules/label/types';

export const getProjectsPreview = createAsyncThunk<
  // Return type of the payload creator
  LabelsListPreviewResponse,
  // First argument to the payload creator
  GetLabelsParams | undefined,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('recent-projects/get', async (payload, thunkApi) => {
  try {
    const response = await projectAPI.getProjectsPreview({
      size: 10,
      ...payload,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const duplicateProject = createAsyncThunk<
  // Return type of the payload creator
  LabelResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  { rejectValue: IErrors }
>('recent-projects/duplicate', async (payload, thunkApi) => {
  try {
    const response = await projectAPI.duplicateProject(payload);

    return response.data;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteProject = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('recent-projects/delete', async (id, thunkApi) => {
  try {
    await projectAPI.deleteProject(id);

    return id;
  } catch (err) {
    const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const editProject = createAsyncThunk<string, EditProjectParams, { rejectValue: IErrors }>(
  'recent-projects/edit',
  async (payload, thunkApi) => {
    try {
      await projectAPI.editProject(payload);

      return payload.id;
    } catch (err) {
      const error: AxiosError<IErrors> = err as AxiosError<IErrors>; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
