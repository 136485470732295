import { lazy } from 'react';

import { routePaths, routePermissions } from '../constants';

import { Module } from 'core/types';

import { PrivateRoute } from 'modules/auth/routes/private-route';

const Templates = lazy(() => import('./templates'));

export const templateModule: Module = {
  routes: [
    <PrivateRoute
      key={routePaths.templates}
      path={routePaths.templates}
      render={() => <Templates />}
      permissions={routePermissions.templates}
    />,
  ],
};
