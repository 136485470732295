/* eslint-disable simple-import-sort/imports */

// Utils
import { mergeModules } from '../core';

// Common user pages
import auth from './auth';
import dashboard from './dashboard';
import profile from './profile';
import projects from './projects';
import defaultRouter from './default-router';
import label from './label';
import pageNotFound from './page-not-found';
import templates from './templates';

// Manage user pages
import { manageModules } from './manage';

//  All modules with "routes" property have to be before the pageNotFound module
export default mergeModules(
  // Common user pages
  auth,
  dashboard,
  profile,
  projects,
  defaultRouter,
  label,
  templates,

  // Manage user pages
  ...manageModules,

  pageNotFound
);
