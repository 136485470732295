import Auth from '@aws-amplify/auth';
import { InternalAxiosRequestConfig } from 'axios';

import { requestCache } from './cache';

export const requestInterceptor = async (prevConfig: InternalAxiosRequestConfig) => {
  const config = prevConfig;

  const { baseURL, data, method, url, params } = config;

  if (requestCache.has(JSON.stringify({ baseURL, data, method, url, params }))) {
    config.headers.set({});

    return {
      ...config,
      method: config.method,
      url: '',
    };
  }

  if (
    baseURL &&
    url?.includes(baseURL) &&
    !requestCache.has(JSON.stringify({ baseURL, data, method, url, params }))
  ) {
    requestCache.add(JSON.stringify({ baseURL, data, method, url, params }));
  }

  const aws = await Auth.currentSession();
  const idToken = aws.getIdToken();

  config.headers.set({
    ...config.headers,
    Authorization: `Bearer ${idToken.getJwtToken()}`,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  });

  return config;
};
