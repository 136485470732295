import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createGraphic,
  deleteBulkGraphics,
  deleteGraphic,
  editGraphic,
  editGraphicTags,
  getGraphics,
  uploadArchiveGraphics,
  uploadBulkGraphics,
} from './actions';

import { PageInfo } from '../types';
import { Content } from 'modules/common/types';

import { resetStoreAction } from 'core/actions';

const name = 'manage/graphics';

type InitialState = {
  graphics: Content[];
  pageInfo: PageInfo;
  loading: boolean;
  error?: string;
  bulkLoading: boolean;
  archiveLoading: boolean;
  successfullyUploaded: string[];
};

const initialState: InitialState = {
  graphics: [],
  pageInfo: {
    offset: 20,
    total: 0,
  },
  loading: false,
  error: undefined,
  bulkLoading: false,
  archiveLoading: false,
  successfullyUploaded: [],
};

const templatesSlice = createSlice({
  name,
  initialState,
  reducers: {
    uploadedBulkGraphics(state, action: PayloadAction<string[]>) {
      state.bulkLoading = false;
      state.successfullyUploaded = action.payload;
    },
    resetUploadedBulkGraphics(state) {
      state.bulkLoading = false;
      state.successfullyUploaded = [];
    },
    resetError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all graphics
    builder.addCase(getGraphics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGraphics.fulfilled, (state, action) => {
      const { items, ...pageInfo } = action.payload;

      state.graphics = items;
      state.pageInfo = pageInfo;
      state.loading = false;
    });
    builder.addCase(getGraphics.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Create new graphic
    builder.addCase(createGraphic.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createGraphic.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createGraphic.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Upload archive graphics
    builder.addCase(uploadArchiveGraphics.pending, (state) => {
      state.archiveLoading = true;
    });
    builder.addCase(uploadArchiveGraphics.fulfilled, (state) => {
      state.archiveLoading = false;
    });
    builder.addCase(uploadArchiveGraphics.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
      state.archiveLoading = false;
    });

    // Upload bulk graphics
    builder.addCase(uploadBulkGraphics.pending, (state) => {
      state.bulkLoading = true;
    });
    builder.addCase(uploadBulkGraphics.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.bulkLoading = false;
    });

    // Edit graphic
    builder.addCase(editGraphic.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editGraphic.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editGraphic.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Edit graphic tags
    builder.addCase(editGraphicTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editGraphicTags.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editGraphicTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Delete graphic
    builder.addCase(deleteGraphic.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteGraphic.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGraphic.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Bulk delete graphics
    builder.addCase(deleteBulkGraphics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkGraphics.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBulkGraphics.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
  },
});

export const { uploadedBulkGraphics, resetUploadedBulkGraphics, resetError } =
  templatesSlice.actions;
export default templatesSlice.reducer;
