import { useCallback, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { isMobile } from 'react-device-detect';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'core';

import { resetStoreAction } from 'core/actions';
import { routePaths } from 'modules/auth/constants';

export const useCurrentUser = () => {
  const dispatch = useAppDispatch();

  const {
    userInfo: currentUser,
    loading,
    error,
  } = useSelector((state: RootState) => state.auth, shallowEqual);

  const logout = useCallback(async () => {
    await Auth.signOut({ global: true });

    const path = isMobile ? `${routePaths.root}?webview=mobile&authPage=signin` : routePaths.root;
    window.location.href = path;

    dispatch(resetStoreAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getUserSession = useCallback(async () => {
    try {
      const result = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      if (!result.attributes) {
        throw new Error('The user is not authenticated');
      }
    } catch (error) {
      logout();
      window.localStorage.clear();
    }
  }, [logout]);

  useEffect(() => {
    getUserSession();
  }, [getUserSession]);

  return {
    currentUser,
    loading,
    error,
  };
};
