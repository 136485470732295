import { createSlice } from '@reduxjs/toolkit';

import { getIcons } from '../actions';

import { Content } from 'modules/common/types';

import { resetStoreAction } from 'core/actions';
import { mergeByProp } from 'modules/common/utils';

const name = 'label/icons';

type InitialState = {
  data: Content[];
  loading: boolean;
  error?: string;
  total?: number;
  page: number;
};

const initialState: InitialState = {
  data: [],
  loading: false,
  error: undefined,
  total: undefined,
  page: 0,
};

const iconsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearIcons(state) {
      state.data = [];
      state.page = 0;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all icons
    builder.addCase(getIcons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIcons.fulfilled, (state, action) => {
      const newItems = [...state.data, ...action.payload.items];

      // getting unique items list
      const filteredItemsById = mergeByProp(newItems, 'id');
      state.data = filteredItemsById;

      state.total = action.payload.total;
      state.page = state.page + 1;
      state.loading = false;
    });
    builder.addCase(getIcons.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
  },
});

export const { clearIcons } = iconsSlice.actions;
export default iconsSlice.reducer;
