import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const Fallback = () => {
  return (
    <Box sx={styles.container}>
      <CircularProgress size={40} />
    </Box>
  );
};
