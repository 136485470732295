import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IErrors } from 'core/types';
import { PublicContent, PublicContentsParams, PublicContentsType } from 'modules/common/types';

import commonPublicContentAPI from 'modules/common/api.services/public-content.api.service';

export const getTemplates = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('templates/getAll', async (payload, thunkApi) => {
  try {
    const response = await commonPublicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Template,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});
