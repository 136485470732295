export const routePaths = {
  root: '/',
  shapes: '/manage/shapes',
  icons: '/manage/icons',
  graphics: '/manage/graphics',
  templates: '/manage/templates',
};

export const routePermissions = {
  shapes: ['ld-editors'],
  icons: ['ld-editors'],
  graphics: ['ld-editors'],
  templates: ['ld-editors'],
};
