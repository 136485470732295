import { lazy, ReactChild, Suspense } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

import AuthBackground from 'assets/images/auth-background.png';
import { Fallback } from './fallback';
import { Footer } from './footer';

import { ThemeProvider } from 'modules/common/theme-provider';

const Carousel = lazy(() => import('./carousel'));
const ConfirmSignUp = lazy(() => import('modules/auth/containers/confirm-sign-up'));
const CreateNewPassword = lazy(() => import('modules/auth/containers/create-new-password'));

const styles = {
  container: {
    height: '100vh',
    p: '2rem',
    overflow: 'auto',
  },
  animationContainer: {
    display: {
      xs: 'none',
      md: 'block',
    },
  },
  animationInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '1.5rem',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${AuthBackground})`,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    flex: 5,
    alignItems: {
      sm: 'center',
    },
  },
  formInnerContainer: {
    width: '100%',
    maxWidth: 450,
    margin: '0 auto',
  },
  footerContainer: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
    flex: 1,
    alignItems: 'end',
    justifyContent: 'center',
  },
};

type Props = {
  children: ReactChild;
  animation?: boolean;
};

const HELPER_ROUTES = ['/confirm-signup', '/reset-password'];

const AuthLayoutComponent = ({ children, animation = true }: Props) => {
  const location = useLocation();

  const isHelperRoute = HELPER_ROUTES.includes(location.pathname);

  const hasAnimation = !isHelperRoute && animation;

  return (
    <ThemeProvider>
      <Grid container sx={styles.container} alignItems="stretch">
        {hasAnimation && (
          <Grid item sm={6} md={5} sx={styles.animationContainer}>
            <Box sx={styles.animationInnerContainer}>
              <Suspense fallback={<Fallback />}>
                <Carousel />
              </Suspense>
            </Box>
          </Grid>
        )}
        <Grid item {...(hasAnimation ? { xs: 12, md: 7 } : { xs: 12 })}>
          <Box sx={styles.mainContainer}>
            <Box sx={styles.formContainer}>
              <Box sx={styles.formInnerContainer}>
                <Suspense fallback={<Fallback />}>
                  <Switch>
                    <Route exact path="/confirm-signup">
                      <ConfirmSignUp />
                    </Route>
                    <Route exact path="/reset-password">
                      <CreateNewPassword />
                    </Route>
                    <Route>{children}</Route>
                  </Switch>
                </Suspense>
              </Box>
            </Box>
            <Box sx={styles.footerContainer}>
              <Footer />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export const AuthLayout = (props: Props) => {
  return (
    <Router>
      <AuthLayoutComponent {...props} />
    </Router>
  );
};
