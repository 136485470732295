// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RemoveEmptyValues<T = Record<string, any>> = (values?: T) => T;

export const removeEmptyValues: RemoveEmptyValues = (values) => {
  if (!values) {
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const newEntries = Object.entries(values).filter(([_key, value]) => {
    if (typeof value === 'boolean' || value === 0) return true;

    return !!value;
  });

  return Object.fromEntries(newEntries);
};
