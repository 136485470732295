import { createSlice } from '@reduxjs/toolkit';

import { getTemplates } from './actions';

import { ITemplate } from './types';

import { resetStoreAction } from 'core/actions';
import { Units } from 'core/constants';
import { mergeByProp } from 'modules/common/utils';

const name = 'templates';

type InitialState = {
  search: boolean;
  templates: ITemplate[];
  loading: boolean;
  error?: string;
  total: number;
  page: number;
};

const initialState: InitialState = {
  search: false,
  templates: [],
  loading: false,
  error: undefined,
  total: 0,
  page: 0,
};

const templatesSlice = createSlice({
  name,
  initialState,
  reducers: {
    searchTemplates(state) {
      state.search = true;
      state.page = 0;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get templates
    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      const { items, total } = action.payload;

      const templates = items.map<ITemplate>(({ id, form, preview, properties }) => {
        const { circle, unit, height, width, previewColor } = properties;

        return {
          id,
          data: JSON.parse(form),
          preview: preview as string,
          properties: {
            circle: circle as boolean,
            previewColor: previewColor ?? 'rgb(255, 255, 255)',
            labelTitle: 'Label Title',
            sizeInUnit: {
              unit: unit as Units,
              height: height as number,
              width: width as number,
            },
          },
        };
      });

      const newTemplates = [...(!state.search ? state.templates : []), ...templates];

      // getting unique templates list
      const filteredTemplatesById = mergeByProp(newTemplates, 'id');

      state.templates = filteredTemplatesById as typeof state.templates;
      state.search = false;
      state.total = total;
      state.page = state.page + 1;
      state.loading = false;
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
  },
});

export const { searchTemplates } = templatesSlice.actions;
export default templatesSlice.reducer;
