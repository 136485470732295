import { Semaphore } from 'async-mutex';
import { httpClient, removeEmptyValues } from 'core';

import { DeletePublicContentParams } from './types';
import { Content, PublicContentsParams } from 'modules/common/types';

const dataSerializer = (params: PublicContentsParams) => {
  const serializedParams = {
    ...params,
    name: params.name?.trim(),
    tags: params.tags
      ?.split(',')
      .map((tag) => tag.trim())
      .join(','),
  };

  return removeEmptyValues(serializedParams);
};

const transformRequest = (data: PublicContentsParams) => {
  const newData = new FormData();

  const serializedData = dataSerializer(data);

  Object.keys(serializedData).forEach((param) => newData.append(param, serializedData[param]));

  return newData;
};

const createPublicContent = async (
  data: PublicContentsParams
): Promise<{
  data: Content;
}> => {
  return await httpClient.post('/public-content', data, {
    transformRequest,
  });
};

const createPublicContents = async (data: Omit<Content, 'id'>) => {
  return await httpClient.post('/public-contents', data);
};

const uploadArchivePublicContents = async (
  data: PublicContentsParams
): Promise<{
  data: Content;
}> => {
  return await httpClient.post('/public-contents/upload-archive', data, {
    transformRequest,
  });
};

const uploadBulkPublicContents = async (
  params: PublicContentsParams[],
  onSuccess?: (data: { name: string; mimeType: string }[]) => void
) => {
  const semaphore = new Semaphore(5);

  let requestsCount = 0;
  const successfullyUploaded: { name: string; mimeType: string }[] = [];

  params.forEach(async (param) => {
    // acquire the semaphore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, release] = await semaphore.acquire();

    try {
      const response = await createPublicContent(param);

      successfullyUploaded.push({
        name: response.data.properties.name,
        mimeType: response.data.properties['mime-type'],
      });
    } catch (error) {
      console.log('request failed.', error);
    } finally {
      requestsCount++;

      if (requestsCount === params.length) {
        onSuccess?.(successfullyUploaded);
      }
      // release the semaphore again so a new request can be send
      release();
    }
  });
};

const editPublicContent = async (
  data: PublicContentsParams
): Promise<{
  data: Content;
}> => {
  return await httpClient.put(`/public-content`, data, {
    transformRequest,
  });
};

const editPublicContentTags = async (
  data: PublicContentsParams
): Promise<{
  data: Content;
}> => {
  const serializedData = dataSerializer(data);

  return await httpClient.patch(`/public-contents`, {
    ...serializedData,
    tags: serializedData.tags.split(','),
  });
};

const editBulkPublicContentTags = async (contents: PublicContentsParams[]) => {
  const serializedData = contents.map((content) => {
    const serializedContent = dataSerializer(content);

    return {
      ...serializedContent,
      tags: serializedContent.tags.split(','),
    };
  });

  return await httpClient.patch(`/public-contents`, serializedData);
};

const deletePublicContent = async ({ contentType, id }: DeletePublicContentParams) => {
  return await httpClient.delete(`/public-contents/${id}`, {
    params: {
      contentType,
    },
  });
};

const deleteBulkPublicContents = async ({ contentType, ids }: DeletePublicContentParams) => {
  const data = ids?.map((id) => ({
    contentType,
    id,
  }));
  return await httpClient.post('/public-contents/delete-contents', data);
};

export default {
  createPublicContent,
  createPublicContents,
  uploadBulkPublicContents,
  uploadArchivePublicContents,
  editPublicContent,
  editPublicContentTags,
  editBulkPublicContentTags,
  deletePublicContent,
  deleteBulkPublicContents,
};
