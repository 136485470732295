import axios, { AxiosError, CancelTokenSource } from 'axios';
import config from 'config';

import { errorInterceptor, requestInterceptor, responseInterceptor } from '../interceptors';

const { BASE_API_URL } = config;
const httpClient = axios.create();

httpClient.defaults.baseURL = BASE_API_URL;
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
httpClient.interceptors.request.use(requestInterceptor, errorInterceptor);

export const generateSource = () => axios.CancelToken.source();

export const getCancelToken = (source: CancelTokenSource) => source.token;

export const cancelRequest = (source: CancelTokenSource) => {
  const message = 'Cancel request';
  return source.cancel(message);
};

export const isCancel = (error: AxiosError) => axios.isCancel(error);

export default httpClient;
