import { TSizeWithUnit } from './types';

export const canvasSizeInPxWeb = {
  width: 550,
  height: 650,
};

export enum FontCategory {
  AllFonts = 'all-fonts',
  Serif = 'serif',
  SansSerif = 'sans-serif',
  Display = 'display',
  Handwriting = 'handwriting',
  Monospace = 'monospace',
}

export const fontsCategories = [
  {
    name: FontCategory.AllFonts,
    label: 'All fonts',
    fontFamily: 'Nunito Sans',
  },
  {
    name: FontCategory.Serif,
    label: 'Serif',
    fontFamily: 'Times New Roman',
  },
  {
    name: FontCategory.SansSerif,
    label: 'Sans-Serif',
    fontFamily: 'Arial',
  },
  {
    name: FontCategory.Display,
    label: 'Display',
    fontFamily: 'Abril Fatface',
  },
  {
    name: FontCategory.Handwriting,
    label: 'Handwriting',
    fontFamily: 'Square Peg',
  },
  {
    name: FontCategory.Monospace,
    label: 'Monospace',
    fontFamily: 'Cutive Mono',
  },
];

export const defaultFontSize = 20;

// '#C7D0D8' - removed gray color, could be required in future
export const COLORS = ['#fff', '#000'];

export enum Units {
  Millimeters = 'mm',
  Inches = 'in',
}

export const INCHES_IN_MILLIMETERS = 25.4;
export const PX_IN_INCH = 0.0104166667;

export const rectangleSizeTemplates: TSizeWithUnit[] = [
  {
    width: 2,
    height: 1,
    unit: Units.Inches,
  },
  {
    width: 4,
    height: 6,
    unit: Units.Inches,
  },
  {
    width: 1,
    height: 2,
    unit: Units.Inches,
  },
  {
    width: 6,
    height: 4,
    unit: Units.Inches,
  },
];

export const circleSizeTemplates: TSizeWithUnit = {
  width: 2,
  height: 2,
  unit: Units.Inches,
};

export enum CanvasBackgroundColors {
  Yellow = 'rgb(252, 236, 90)',
  Blue = 'rgb(183, 225, 236)',
  Pink = 'rgb(246, 198, 220)',
  Green = 'rgb(215, 240, 154)',
  Purple = 'rgb(198, 184, 229)',
  White = 'rgb(255, 255, 255)',
}
