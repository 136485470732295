import { createSlice } from '@reduxjs/toolkit';

import { getTemplates, getTemplateTags } from '../actions';

import { PublicContentTags } from 'modules/common/types';
import { ITemplate } from 'modules/templates/types';

import { resetStoreAction } from 'core/actions';
import { Units } from 'core/constants';
import { mergeByProp } from 'modules/common/utils';

const name = 'label/templates';

type InitialState = {
  data: ITemplate[];
  loading: boolean;
  tags: PublicContentTags['tags'];
  tagsLoading: boolean;
  error?: string;
  total?: number;
  page: number;
};

const initialState: InitialState = {
  data: [],
  tags: [],
  loading: false,
  tagsLoading: false,
  error: undefined,
  total: undefined,
  page: 0,
};

const templatesSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearTemplates(state) {
      state.data = [];
      state.page = 0;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get templates
    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      const { items, total } = action.payload;

      const data = items.map<ITemplate>(({ id, form, preview, properties }) => {
        const { circle, unit, height, width, previewColor } = properties;

        return {
          id,
          data: JSON.parse(form),
          preview: preview as string,
          properties: {
            circle: circle as boolean,
            previewColor: previewColor ?? 'rgb(255, 255, 255)',
            labelTitle: 'Label Title',
            sizeInUnit: {
              unit: unit as Units,
              height: height as number,
              width: width as number,
            },
          },
        };
      });

      const newItems = [...state.data, ...data];

      // getting unique items list
      const filteredItemsById = mergeByProp(newItems, 'id');

      state.data = filteredItemsById as typeof state.data;
      state.total = total;
      state.page = state.page + 1;
      state.loading = false;
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Get tags
    builder.addCase(getTemplateTags.pending, (state) => {
      state.tagsLoading = true;
    });
    builder.addCase(getTemplateTags.fulfilled, (state, action) => {
      state.tags = action.payload.tags ?? [];
      state.tagsLoading = false;
    });
    builder.addCase(getTemplateTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.tagsLoading = false;
    });
  },
});

export const { clearTemplates } = templatesSlice.actions;
export default templatesSlice.reducer;
