import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = <T = Record<string, unknown>>(
  initialSearchParams?: T
): {
  searchParams: T;
  setSearchParams: (params: T) => void;
  getSearchParams: (params?: string) => T;
} => {
  const location = useLocation();
  const history = useHistory();

  const getSearchParams = useCallback(
    (params?: string): T => {
      const searchParams = new URLSearchParams(params ?? location.search);

      return Object.fromEntries(searchParams.entries()) as T;
    },
    [location.search]
  );

  const handleSetSearchParams = useCallback(
    (params: T) => {
      if (params) {
        const searchParams = new URLSearchParams(params).toString();
        history.replace({ search: searchParams });
      }
    },
    [history]
  );

  const searchParams = useMemo(() => {
    return getSearchParams();
  }, [getSearchParams]);

  useEffect(() => {
    if (initialSearchParams) {
      handleSetSearchParams(initialSearchParams);
    }
  }, [handleSetSearchParams, initialSearchParams]);

  return { searchParams, setSearchParams: handleSetSearchParams, getSearchParams };
};
