import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createIcon,
  deleteBulkIcons,
  deleteIcon,
  editBulkIconTags,
  editIcon,
  editIconTags,
  getIcons,
  uploadArchiveIcons,
  uploadBulkIcons,
} from './actions';

import { PageInfo } from '../types';
import { Content } from 'modules/common/types';

import { resetStoreAction } from 'core/actions';

const name = 'manage/icons';

type InitialState = {
  icons: Content[];
  pageInfo: PageInfo;
  loading: boolean;
  error?: string;
  bulkLoading: boolean;
  archiveLoading: boolean;
  successfullyUploaded: string[];
};

const initialState: InitialState = {
  icons: [],
  pageInfo: {
    offset: 20,
    total: 0,
  },
  loading: false,
  error: undefined,
  bulkLoading: false,
  archiveLoading: false,
  successfullyUploaded: [],
};

const templatesSlice = createSlice({
  name,
  initialState,
  reducers: {
    uploadedBulkIcons(state, action: PayloadAction<string[]>) {
      state.bulkLoading = false;
      state.successfullyUploaded = action.payload;
    },
    resetUploadedBulkIcons(state) {
      state.bulkLoading = false;
      state.successfullyUploaded = [];
    },
    resetError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all icons
    builder.addCase(getIcons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIcons.fulfilled, (state, action) => {
      const { items, ...pageInfo } = action.payload;

      state.icons = items;
      state.pageInfo = pageInfo;
      state.loading = false;
    });
    builder.addCase(getIcons.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Create new icon
    builder.addCase(createIcon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createIcon.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createIcon.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Upload archive icons
    builder.addCase(uploadArchiveIcons.pending, (state) => {
      state.archiveLoading = true;
    });
    builder.addCase(uploadArchiveIcons.fulfilled, (state) => {
      state.archiveLoading = false;
    });
    builder.addCase(uploadArchiveIcons.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
      state.archiveLoading = false;
    });

    // Upload bulk icons
    builder.addCase(uploadBulkIcons.pending, (state) => {
      state.bulkLoading = true;
    });
    builder.addCase(uploadBulkIcons.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.bulkLoading = false;
    });

    // Edit icon
    builder.addCase(editIcon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editIcon.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editIcon.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Edit icon tags
    builder.addCase(editIconTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editIconTags.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editIconTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Edit bulk icon tags
    builder.addCase(editBulkIconTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editBulkIconTags.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editBulkIconTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Delete icon
    builder.addCase(deleteIcon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteIcon.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteIcon.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Bulk delete icons
    builder.addCase(deleteBulkIcons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkIcons.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBulkIcons.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
  },
});

export const { uploadedBulkIcons, resetUploadedBulkIcons, resetError } = templatesSlice.actions;
export default templatesSlice.reducer;
