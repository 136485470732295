import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const styles = {
  container: {
    mt: '2rem',
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: {
      xs: 2,
      sm: 4,
    },
    fontSize: '14px',
    '& > a': {
      color: '#969BB2',
    },
  },
  meta: {
    color: '#E1E1E1',
    fontSize: '14px',
    fontWeight: 400,
  },
};

export const Footer = () => {
  return (
    <Box component="footer" sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Link
          href="https://www.rollo.com/terms-of-service/"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer nofollow">
          Terms of Service
        </Link>
        <Link
          href="https://www.rollo.com/privacy-policy/"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer nofollow">
          Privacy Policy
        </Link>
        <Link
          href="http://support.rolloprinter.com/support/home"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer nofollow">
          Contact Support
        </Link>
      </Box>
      <Typography sx={styles.meta} component="p" align="center">
        Copyright © 2021–2023 Nelu LLC
      </Typography>
    </Box>
  );
};
