import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  wrapper: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
  },
  spinner: {},
};

export const Preloader = () => {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress sx={styles.spinner} size={40} />
    </Box>
  );
};
