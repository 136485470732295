import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'core';
import mine from 'mime';

import publicContentAPI from '../api.service';
import { refreshDataAfterUpdate } from '../utils';
import { uploadedBulkShapes } from './shapes.reducer';

import { DeletePublicContentParams } from '../types';
import { IErrors } from 'core/types';
import { PublicContent, PublicContentsParams, PublicContentsType } from 'modules/common/types';

import commonPublicContentAPI from 'modules/common/api.services/public-content.api.service';

export const getShapes = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('manage/shapes', async (payload, thunkApi) => {
  try {
    const response = await commonPublicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Shape,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const createShape = createAsyncThunk<
  void,
  PublicContentsParams & { onSuccess: () => void },
  { rejectValue: IErrors; state: RootState }
>('manage/shapes/create', async ({ onSuccess, ...payload }, thunkApi) => {
  try {
    await publicContentAPI.createPublicContent({
      contentType: PublicContentsType.Shape,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getShapes(params));
    };

    refreshDataAfterUpdate({
      contentType: PublicContentsType.Shape,
      store: thunkApi.getState(),
      refreshCb,
    });

    onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const uploadArchiveShapes = createAsyncThunk<
  void,
  PublicContentsParams,
  { rejectValue: string; state: RootState }
>('manage/shapes/upload/archive', async (payload, thunkApi) => {
  try {
    await publicContentAPI.uploadArchivePublicContents({
      contentType: PublicContentsType.Shape,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getShapes(params));
    };

    refreshDataAfterUpdate({
      contentType: PublicContentsType.Shape,
      store: thunkApi.getState(),
      refreshCb,
    });
  } catch (err) {
    const error = err as AxiosError<string>;
    if (!error.response) {
      throw err;
    }

    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const uploadBulkShapes = createAsyncThunk<
  void,
  PublicContentsParams[],
  { rejectValue: IErrors; state: RootState }
>('manage/shapes/upload/bulk', async (payload, thunkApi) => {
  try {
    const handleSemaphoreResult = (successfullyUploaded: { name: string; mimeType: string }[]) => {
      const successfullyUploadedFiles = successfullyUploaded.map((item) => {
        const fileExtension = mine.getExtension(item.mimeType);
        return `${item.name}.${fileExtension}`;
      });

      thunkApi.dispatch(uploadedBulkShapes(successfullyUploadedFiles));

      const refreshCb = (params: PublicContentsParams) => {
        thunkApi.dispatch(getShapes(params));
      };

      refreshDataAfterUpdate({
        contentType: PublicContentsType.Shape,
        store: thunkApi.getState(),
        refreshCb,
      });
    };

    await publicContentAPI.uploadBulkPublicContents(payload, handleSemaphoreResult);
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const editShape = createAsyncThunk<
  void,
  PublicContentsParams & { onSuccess: () => void },
  { rejectValue: IErrors; state: RootState }
>('manage/shapes/edit', async ({ onSuccess, ...payload }, thunkApi) => {
  try {
    await publicContentAPI.editPublicContent({
      contentType: PublicContentsType.Shape,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getShapes(params));
    };

    refreshDataAfterUpdate({
      initialState: false,
      contentType: PublicContentsType.Shape,
      store: thunkApi.getState(),
      refreshCb,
    });

    onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const editShapeTags = createAsyncThunk<
  void,
  PublicContentsParams,
  { rejectValue: IErrors; state: RootState }
>('manage/shapes/edit/tags', async (payload, thunkApi) => {
  try {
    await publicContentAPI.editPublicContentTags({
      contentType: PublicContentsType.Shape,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getShapes(params));
    };

    refreshDataAfterUpdate({
      initialState: false,
      contentType: PublicContentsType.Shape,
      store: thunkApi.getState(),
      refreshCb,
    });
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteShape = createAsyncThunk<
  void,
  DeletePublicContentParams & { onSuccess: () => void },
  { rejectValue: IErrors; state: RootState }
>('manage/shapes/delete', async (payload, thunkApi) => {
  try {
    await publicContentAPI.deletePublicContent({
      contentType: PublicContentsType.Shape,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getShapes(params));
    };

    refreshDataAfterUpdate({
      initialState: false,
      contentType: PublicContentsType.Shape,
      store: thunkApi.getState(),
      refreshCb,
    });

    payload.onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteBulkShapes = createAsyncThunk<
  void,
  DeletePublicContentParams & { onSuccess: () => void },
  { rejectValue: IErrors; state: RootState }
>('manage/shapes/delete/bulk', async (payload, thunkApi) => {
  try {
    await publicContentAPI.deleteBulkPublicContents({
      contentType: PublicContentsType.Shape,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getShapes(params));
    };

    refreshDataAfterUpdate({
      contentType: PublicContentsType.Shape,
      store: thunkApi.getState(),
      refreshCb,
    });

    payload.onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});
