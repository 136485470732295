import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Preloader } from 'components/preloader';
import { routePaths } from '../constants';
import { useCurrentUser } from '../hooks/use-current-user';
import { usePermissions } from '../hooks/use-permissions';

type Props = RouteProps & {
  permissions: string[];
};

export const PrivateRoute = ({ permissions, ...props }: Props) => {
  const { currentUser, loading } = useCurrentUser();
  const { hasPermission } = usePermissions();

  if ((!currentUser?.email || !hasPermission(permissions)) && !loading) {
    return <Redirect to={routePaths.root} />;
  }

  if (loading) {
    return <Preloader />;
  }

  return <Route {...props} />;
};
