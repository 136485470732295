import Typography from '@mui/material/Typography';

interface Props {
  msg?: string;
  fontSize?: number;
}

export const Error = ({ msg, fontSize }: Props) => {
  return (
    <Typography
      component="p"
      align="center"
      sx={{
        fontSize: fontSize || 14,
        color: (theme) => theme.palette.error.main,
      }}>
      {msg}
    </Typography>
  );
};
