import { ReactNode, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ReactComponent as Logo } from 'assets/main-icons/label-logo.svg';
import { Button } from '../components/button';

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4,
  },
  title: {
    fontWeight: 800,
    fontSize: {
      xs: '2rem',
      sm: '2.5rem',
    },
  },
  signIn: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
  },
};

export const BaseMessageView = ({
  title,
  message,
  action,
  processing,
  onAction,
  onSignIn,
}: {
  title: ReactNode;
  message: ReactNode;
  action: ReactNode;
  processing: boolean;
  onAction: (e: SyntheticEvent) => void;
  onSignIn?: (e: SyntheticEvent) => void;
}) => {
  return (
    <Box sx={styles.container}>
      <Grid container spacing={4}>
        <Grid item xs={12} textAlign="center">
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" align="center" sx={styles.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {processing ? (
            <Grid item xs={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Typography component="p" align="center">
              {message}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{
          xs: 3,
          sm: 4,
        }}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={processing}
            onClick={onAction}>
            {action}
          </Button>
        </Grid>
        {onSignIn && (
          <Grid item xs={12}>
            <Typography align="center" sx={styles.signIn} onClick={onSignIn}>
              Back to Sign In
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
