import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getGoogleFonts, resetStoreAction } from './actions';

import { Font } from 'core/types';

type InitialState = {
  fonts: Font[];
  selectedFont?: Font;
  fontsLoading: boolean;
  forceUpdate: boolean;
};

const initialState: InitialState = {
  fonts: [],
  selectedFont: undefined,
  fontsLoading: true,
  forceUpdate: false,
};

const rootReducer = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setSelectedFont: (state, action: PayloadAction<Font | undefined>) => {
      state.selectedFont = action.payload;
    },
    // Force update
    forceUpdateAction: (state, action: PayloadAction<boolean>) => {
      state.forceUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get google fonts
    builder.addCase(getGoogleFonts.pending, (state) => {
      state.fontsLoading = true;
    });
    builder.addCase(getGoogleFonts.fulfilled, (state, { payload }: PayloadAction<Font[]>) => {
      state.fontsLoading = false;
      state.fonts = payload;
    });
    builder.addCase(getGoogleFonts.rejected, (state) => {
      state.fontsLoading = false;
    });
  },
});

export const { setSelectedFont, forceUpdateAction } = rootReducer.actions;
export default rootReducer.reducer;
