import { createSlice } from '@reduxjs/toolkit';

import { deleteUser } from './actions';

import { resetStoreAction } from 'core/actions';

const name = 'profile';

type InitialState = {
  loading: boolean;
  error?: string;
};

const initialState: InitialState = {
  loading: false,
  error: undefined,
};

const profileSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

export default profileSlice.reducer;
