import { ReactChild } from 'react';
import Box from '@mui/material/Box';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    position: 'relative',
    width: '100%',
  },
  content: {
    position: 'relative',
    flexGrow: 1,
  },
};

export const PageLayout = ({ children }: { children: ReactChild }) => {
  return (
    <Box sx={styles.root}>
      <Box component="main" sx={styles.content}>
        {children}
      </Box>
    </Box>
  );
};
