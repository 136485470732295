import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import config from 'config';
import * as zod from 'zod';

import PlayStoreIcon from 'assets/images/android-badge.png';
import AppStoreIcon from 'assets/images/apple-badge.png';
import { ReactComponent as Logo } from 'assets/main-icons/label-logo.svg';
import { TextInput } from 'components/inputs/text-input';
import { AuthLayout } from '../components/auth-layout/auth-layout';
import { Button } from '../components/button';
import { Error } from '../components/form/error';

import { AuthenticatorProps } from '../types';
import { PublicContentsType } from 'modules/common/types';

import { routePaths } from 'modules/auth/constants';
const { BASE_API_URL } = config;

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4,
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  previewContainer: {
    padding: '12px',
    backgroundColor: '#F9FAFF',
    borderRadius: '12px',
  },
  imagePreview: {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
  },
  title: {
    fontWeight: 800,
    lineHeight: 1,
    fontSize: {
      xs: '2rem',
      lg: '2.5rem',
    },
  },
  notification: {
    fontWeight: 700,
    fontSize: '2rem',
    opacity: '0.7',
  },
  forgotPassword: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
    mt: '1rem',
  },
  signUp: {
    text: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    link: {
      color: 'primary.main',
      fontWeight: 600,
      fontSize: '1rem',
      cursor: 'pointer',
    },
  },
  appLink: {
    imgWrapper: {
      height: '55px',
      width: '190px',
      backgroundSize: 'auto',
    },
  },
};

type FormValues = {
  email: string;
  password: string;
};

const defaultValues: FormValues = {
  email: '',
  password: '',
};

const schema = zod.object({
  email: zod
    .string()
    .min(5, { message: 'Field is required' })
    .email({ message: 'Invalid email address' }),
  password: zod.string().min(3, { message: 'Field is required' }),
});

const getTemplate = async (id: string) => {
  return await axios.get(`/public-contents/${id}`, {
    baseURL: BASE_API_URL,
    params: {
      contentType: PublicContentsType.Template,
    },
  });
};

const SignInComponent = (props: AuthenticatorProps) => {
  const location = useLocation();
  const history = useHistory();

  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get('email');
  const authPage = urlParams.get('authPage');
  const templateId = urlParams.get('templateId');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [templatePreviewUrl, setTemplatePreviewUrl] = useState<string>('');

  useEffect(() => {
    localStorage.setItem('amplify-authenticator-authState', 'signIn');
  }, []);

  useEffect(() => {
    (async () => {
      if (templateId) {
        try {
          const { data } = await getTemplate(templateId);
          setTemplatePreviewUrl(data.preview);
        } catch (error) {
          console.log('error --->', error);
        }
      }
    })();
  }, [templateId]);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      ...(email && { email }),
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (authPage) {
      urlParams.delete('authPage');
      const formattedSearchParams = urlParams.toString();

      history.push(`${location.pathname}?${formattedSearchParams}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPage]);

  const handleChangeState = (authState: string) => {
    props.onStateChange?.(authState);
  };

  const onSubmit = async ({ email, password }: FormValues) => {
    setLoading(true);
    try {
      const user = await Auth.signIn({
        username: email,
        password,
      });

      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        props.onStateChange?.('confirmSignIn', user);
        return;
      }

      if (!templateId) {
        history.push(routePaths.dashboard);
      } else {
        history.push(routePaths.template(templateId));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(String(error.message));
    } finally {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  if (props.authState !== 'signIn') {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Box sx={styles.container}>
          <Grid container spacing={4}>
            <Grid item xs={12} sx={styles.logoContainer}>
              {!templateId ? (
                <Logo />
              ) : (
                <Box sx={styles.previewContainer}>
                  <CardMedia component="img" sx={styles.imagePreview} image={templatePreviewUrl} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" sx={styles.title} align="center">
                {!templateId ? 'Sign in to Rollo' : 'Sign in to Rollo to use template'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="email"
                control={control}
                errors={errors}
                placeholder="Email"
                variant="standard"
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="password"
                control={control}
                errors={errors}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        onMouseDown={(e) => e.preventDefault()}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                align="right"
                onClick={() => handleChangeState('forgotPassword')}
                sx={styles.forgotPassword}>
                Forgot password?
              </Typography>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Error msg={error} fontSize={18} />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 4,
            }}>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                type="submit">
                Sign In
              </Button>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" gap={1}>
              <Typography component="span" sx={styles.signUp.text}>
                Need an account?
              </Typography>
              <Typography
                component="span"
                onClick={() => handleChangeState('signUp')}
                sx={styles.signUp.link}>
                Sign up
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid
                item
                xs={12}
                display={{
                  xs: 'none',
                  sm: 'flex',
                }}
                justifyContent="space-evenly"
                flexWrap="wrap"
                gap={2}>
                <CardMedia
                  sx={styles.appLink.imgWrapper}
                  component="a"
                  href="https://apps.apple.com/us/app/rollo-design/id1606405048"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  image={AppStoreIcon}
                />

                <CardMedia
                  sx={styles.appLink.imgWrapper}
                  component="a"
                  href="https://play.google.com/store/apps/details?id=com.rollo.designer"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  image={PlayStoreIcon}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
    </>
  );
};

export const SignIn = (props: AuthenticatorProps) => {
  if (props.authState !== 'signIn') {
    return null;
  }

  return (
    <AuthLayout>
      <SignInComponent {...props} />
    </AuthLayout>
  );
};
