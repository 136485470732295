import { ReactElement } from 'react';
import { Middleware } from 'redux';

import { Module, Modules, SideBar } from '../types';

export const mergeModules = (...modules: Module[]): Modules => {
  const routes = modules.reduce(
    (acc: ReactElement[], { routes }) => (routes ? [...acc, ...routes] : acc),
    []
  );
  const sideBarItems = modules.reduce(
    (acc: SideBar[], { sideBarItems }) => (sideBarItems ? [...acc, ...sideBarItems] : acc),
    []
  );
  const middlewares = modules.reduce(
    (acc: Middleware[], { middlewares }) => (middlewares ? [...acc, ...middlewares] : acc),
    []
  );
  return { routes, sideBarItems, middlewares };
};
