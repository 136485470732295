import { createSlice } from '@reduxjs/toolkit';

import { getUserInfo, getUserInfoShadow } from './actions';

import { IAuthStore } from './types';

import { resetStoreAction } from 'core/actions';

const name = 'auth';

const initialState: IAuthStore = {
  loading: true,
  error: '',
  userInfo: {} as IAuthStore['userInfo'],
};

const rootReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get user profile
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.userInfo = payload;
      state.loading = false;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });

    // Get user profile shadow mode
    builder.addCase(getUserInfoShadow.fulfilled, (state, { payload }) => {
      state.userInfo = payload;
    });
    builder.addCase(getUserInfoShadow.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

export default rootReducer.reducer;
