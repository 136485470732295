import { RootState } from 'core';

import { PublicContentsParams, PublicContentsType } from 'modules/common/types';

export const refreshDataAfterUpdate = ({
  initialState = true,
  contentType,
  store,
  refreshCb,
}: {
  initialState?: boolean;
  contentType: PublicContentsType;
  store: RootState;
  refreshCb: (params: PublicContentsParams) => void;
}) => {
  const location = document.location;

  const offset = store.manage[contentType].pageInfo.offset;

  let initialParams = {
    page: 0,
    size: offset,
  };

  if (!initialState && location.search) {
    const locationSearchParams = new URLSearchParams(location.search);

    const searchParams = Object.fromEntries(locationSearchParams.entries());

    initialParams = {
      ...initialParams,
      ...searchParams,
    };
  }

  refreshCb(initialParams);
};
