import { routePaths as manageRoutePaths } from 'modules/manage/constants';

export const STATUSES = {
  progress: 'PROGRESS',
  failed: 'FAILED',
  success: 'SUCCESS',
  pending: 'PENDING',
};

export const routePaths = {
  root: '/',
  dashboard: '/dashboard',
  label: '/label/new',
  template: (templateId: string) => `/label/template?templateId=${templateId}`,
  projects: '/projects',
  profile: '/profile',
  manage: {
    ...manageRoutePaths,
  },
};
