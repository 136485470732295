import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createShape,
  deleteBulkShapes,
  deleteShape,
  editShape,
  editShapeTags,
  getShapes,
  uploadArchiveShapes,
  uploadBulkShapes,
} from './actions';

import { PageInfo } from '../types';
import { Content } from 'modules/common/types';

import { resetStoreAction } from 'core/actions';

const name = 'manage/shapes';

type InitialState = {
  shapes: Content[];
  pageInfo: PageInfo;
  loading: boolean;
  error?: string;
  bulkLoading: boolean;
  archiveLoading: boolean;
  successfullyUploaded: string[];
};

const initialState: InitialState = {
  shapes: [],
  pageInfo: {
    offset: 20,
    total: 0,
  },
  loading: false,
  error: undefined,
  bulkLoading: false,
  archiveLoading: false,
  successfullyUploaded: [],
};

const templatesSlice = createSlice({
  name,
  initialState,
  reducers: {
    uploadedBulkShapes(state, action: PayloadAction<string[]>) {
      state.bulkLoading = false;
      state.successfullyUploaded = action.payload;
    },
    resetUploadedBulkShapes(state) {
      state.bulkLoading = false;
      state.successfullyUploaded = [];
    },
    resetError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    // Reset store
    builder.addCase(resetStoreAction, () => initialState);

    // Get all shapes
    builder.addCase(getShapes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShapes.fulfilled, (state, action) => {
      const { items, ...pageInfo } = action.payload;

      state.shapes = items;
      state.pageInfo = pageInfo;
      state.loading = false;
    });
    builder.addCase(getShapes.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Create new shape
    builder.addCase(createShape.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createShape.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createShape.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Upload archive shapes
    builder.addCase(uploadArchiveShapes.pending, (state) => {
      state.archiveLoading = true;
    });
    builder.addCase(uploadArchiveShapes.fulfilled, (state) => {
      state.archiveLoading = false;
    });
    builder.addCase(uploadArchiveShapes.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
      state.archiveLoading = false;
    });

    // Upload bulk shapes
    builder.addCase(uploadBulkShapes.pending, (state) => {
      state.bulkLoading = true;
    });
    builder.addCase(uploadBulkShapes.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.bulkLoading = false;
    });

    // Edit shape
    builder.addCase(editShape.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editShape.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editShape.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Edit shape tags
    builder.addCase(editShapeTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editShapeTags.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editShapeTags.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Delete shape
    builder.addCase(deleteShape.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteShape.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteShape.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });

    // Bulk delete shapes
    builder.addCase(deleteBulkShapes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBulkShapes.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBulkShapes.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload?.errorMessage;
      } else {
        state.error = action.error.message;
      }
      state.loading = false;
    });
  },
});

export const { uploadedBulkShapes, resetUploadedBulkShapes, resetError } = templatesSlice.actions;
export default templatesSlice.reducer;
