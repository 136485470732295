import { AxiosProgressEvent } from 'axios';
import { httpClient, removeEmptyValues } from 'core';

import {
  GetImagesParams,
  GetLabelsParams,
  ILabelUnveiled,
  Image,
  ImagesListResponse,
  LabelProperties,
  LabelResponse,
  LabelsListPreviewResponse,
} from './types';
import { GoogleFont } from 'core/types';

const createLabel = async (
  canvasData: fabric.Object[],
  properties: LabelProperties,
  preview: string
) => {
  const { circle, previewColor, sizeInUnit, labelTitle } = properties;

  return await httpClient.post('/project', {
    label: {
      form: JSON.stringify(canvasData),
      preview,
      properties: {
        circle,
        previewColor,
        labelTitle,
        unit: sizeInUnit.unit,
        height: sizeInUnit.height,
        width: sizeInUnit.width,
      },
    },
  });
};

const duplicateLabel = async (
  id: string
): Promise<{
  data: LabelResponse;
}> => {
  return await httpClient.post(`/projects/${id}`);
};

const updateLabel = async (
  id: string,
  canvasData: fabric.Object[],
  properties: LabelProperties,
  preview: string,
  previewId: string
) => {
  const { circle, previewColor, sizeInUnit, labelTitle } = properties;

  return await httpClient.put(`/projects/${id}`, {
    label: {
      form: JSON.stringify(canvasData),
      preview,
      previewId,
      properties: {
        circle,
        previewColor,
        labelTitle,
        unit: sizeInUnit.unit,
        height: sizeInUnit.height,
        width: sizeInUnit.width,
      },
    },
  });
};

const getLabelsPreview = async (
  params?: GetLabelsParams
): Promise<{
  data: LabelsListPreviewResponse;
}> => {
  return await httpClient.get('/projects/preview', {
    params,
  });
};

const getLabel = async (
  id: string
): Promise<{
  data: ILabelUnveiled;
}> => {
  return await httpClient.get(`/projects/${id}`);
};

const deleteLabel = async (
  id: string
): Promise<{
  data: {
    id: string;
    canvas: string;
    updatedAt?: string;
  };
}> => {
  return await httpClient.delete(`/projects/${id}`);
};

const deleteImage = async (id: string) => {
  return await httpClient.delete(`/images/${id}`);
};

const getIcons = async (query: string) => {
  const defaultQuery = 'like';
  return await fetch('https://api.fontawesome.com', {
    method: 'POST',
    body: `query { search(version: "6.1.1", query: "${
      query || defaultQuery
    }", first: 50) { label } }`,
  });
};

const getFonts = async (sortParam: string) => {
  return await httpClient.get<{ kind: string; items: GoogleFont[] }>(
    `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    {
      params: {
        sort: sortParam,
      },
    }
  );
};

const dataSerializer = (params: GetImagesParams) => {
  const requestParams = {
    ...params,
    size: params.size || 10,
  };

  const serializedParams = removeEmptyValues(requestParams);

  return new URLSearchParams(serializedParams).toString();
};

const getImages = async (
  params?: GetImagesParams
): Promise<{
  data: ImagesListResponse;
}> => {
  return await httpClient.get('/images', {
    params,
    paramsSerializer: dataSerializer,
  });
};

const uploadImage = async (
  formData: FormData,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  const { data } = await httpClient.post<Image>('/image', formData, {
    onUploadProgress,
  });
  return data;
};

export default {
  createLabel,
  duplicateLabel,
  getLabelsPreview,
  getLabel,
  updateLabel,
  deleteLabel,
  getIcons,
  getFonts,
  getImages,
  uploadImage,
  deleteImage,
};
