import { httpClient, removeEmptyValues } from 'core';

import {
  GetLabelsParams,
  LabelProperties,
  LabelResponse,
  LabelsListPreviewResponse,
} from 'modules/label/types';

const dataSerializer = (params: GetLabelsParams) => {
  const serializedParams = removeEmptyValues(params);

  return new URLSearchParams(serializedParams).toString();
};

const getProjectsPreview = async (
  params?: GetLabelsParams
): Promise<{
  data: LabelsListPreviewResponse;
}> => {
  return await httpClient.get('/projects/preview', {
    params,
    paramsSerializer: dataSerializer,
  });
};

const duplicateProject = async (
  id: string
): Promise<{
  data: LabelResponse;
}> => {
  return await httpClient.post(`/projects/${id}`);
};

const deleteProject = async (
  id: string
): Promise<{
  data: {
    id: string;
    canvas: string;
    updatedAt?: string;
  };
}> => {
  return await httpClient.delete(`/projects/${id}`);
};

export type EditProjectParams = {
  id: string;
  properties: Partial<LabelProperties>;
};

const editProject = async ({ id, properties }: EditProjectParams) => {
  const { sizeInUnit, ...restProperties } = properties;

  return await httpClient.patch(`/projects/${id}`, {
    ...restProperties,
    ...sizeInUnit,
  });
};

export default {
  getProjectsPreview,
  duplicateProject,
  deleteProject,
  editProject,
};
