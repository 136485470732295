const URL_REGEXP =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i;

export const isValidURL = (url: string) => url.match(URL_REGEXP) !== null;

export const isValidEmail = (email: string) => email.match(EMAIL_REGEXP) !== null;

export const isEmail = (email: string) =>
  !EMAIL_REGEXP.test(email) ? 'Invalid email address' : undefined;
