import { TSize } from 'core/types';

export const getCanvasSize = (
  sizeInPx: { width: number; height: number },
  size: { width: number; height: number }
) => {
  const isLandScape = size.width === size.height || size.width > size.height;
  const ratio = isLandScape ? size.height / size.width : size.width / size.height;
  const baseSize = isLandScape ? sizeInPx.width : sizeInPx.height;

  return isLandScape
    ? { width: baseSize, height: baseSize * ratio }
    : { width: baseSize * ratio, height: baseSize };
};

const BORDER_PERCENT = 0.05;
const MOBILE_HEADER_HEIGHT = 63;
const MOBILE_FOOTER_HEIGHT = 80;

export const getMobileZoomLevel = (
  size: TSize,
  preview?: boolean,
  newWidth?: number,
  newHeight?: number
) => {
  if (preview && newWidth && newHeight) {
    const widthAspectRatio = newWidth / size.width - BORDER_PERCENT;
    const heightAspectRatio = newHeight / size.height - BORDER_PERCENT;

    const zoom = Math.min(widthAspectRatio, heightAspectRatio);

    return zoom;
  } else {
    const widthAspectRatio = window.innerWidth / size.width - BORDER_PERCENT;

    const heightAspectRatio =
      (window.innerHeight - MOBILE_HEADER_HEIGHT - MOBILE_FOOTER_HEIGHT) / size.height -
      BORDER_PERCENT;
    const zoom = Math.min(widthAspectRatio, heightAspectRatio);

    return zoom;
  }
};
