import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';
import { store } from 'core';

import { requestCache } from './cache';

import { forceUpdateAction } from 'core/rootReducer';

export const responseInterceptor = async (response: AxiosResponse) => {
  const { baseURL, data, method, url, params } = response.config;

  if (requestCache.has(JSON.stringify({ baseURL, data, method, url, params }))) {
    requestCache.delete(JSON.stringify({ baseURL, data, method, url, params }));
  }

  if (response.status === 200) {
    const currentAppVersion = Cookies.get('designerAppVersion');
    const appVersion = response.headers['designer-min-app-version'];

    if (appVersion && (!currentAppVersion || currentAppVersion !== appVersion)) {
      Cookies.set('designerAppVersion', appVersion, { expires: 90 });
      store.dispatch(forceUpdateAction(true));
    }
  }
  return response;
};
