import { TSizeWithUnit } from 'core/types';

import { Units } from 'core/constants';

export const canvasDotsWidth = 10000;
export const canvasDotsHeight = 10000;

export const canvasId = 'canvas';

export const pxInMM = 3.779528;
export const pxInPt = 0.75292857248934;

export const ENTER_KEY_CODE = 'Enter';
export const EMPTY_LABEL_TITLE_TEMPLATE = 'Label Title';

export const mainAreaId = 'main-area';

export const MIN_ZOOM_LEVEL = 0.1;
export const MAX_ZOOM_LEVEL = 2;
export const DEFAULT_FONT_SIZE = 18;

export const baseFontSize: { [k: string]: number } = {
  mm: 5,
  in: 4,
  cm: 4,
};

export const sizeConfig: Record<string, TSizeWithUnit & { circle: boolean }> = {
  '2x2': {
    width: 2,
    height: 2,
    unit: Units.Inches,
    circle: true,
  },
  '4x6': {
    width: 4,
    height: 6,
    unit: Units.Inches,
    circle: false,
  },
  '2x1': {
    width: 2,
    height: 1,
    unit: Units.Inches,
    circle: false,
  },
  '6x4': {
    width: 6,
    height: 4,
    unit: Units.Inches,
    circle: false,
  },
};
