import { lazy } from 'react';

import { routePaths, routePermissions } from '../constants';

import { Module } from 'core/types';

import { PrivateRoute } from 'modules/auth/routes/private-route';

const Icons = lazy(() => import('./icons'));

export const iconModule: Module = {
  routes: [
    <PrivateRoute
      key={routePaths.icons}
      path={routePaths.icons}
      render={() => <Icons />}
      permissions={routePermissions.icons}
    />,
  ],
};
