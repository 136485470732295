import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { httpClient } from './services';
import { sleep } from './utils';

import { Font, GoogleFont } from './types';

interface IErrors {
  errorMessage: string;
}

export const resetStoreAction = createAction('store/reset');

export const getGoogleFonts = createAsyncThunk('user/getFonts', async (_, thunkApi) => {
  try {
    // We should get all the fonts from google API directly
    const { data: googleFontsRaw } = await httpClient.get<{ kind: string; items: GoogleFont[] }>(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );

    const fonts = googleFontsRaw.items.reduce((acc, googleFont) => {
      // We need only latin fonts now
      if (!googleFont.subsets.includes('latin')) {
        return acc;
      }

      if (!googleFont.variants.includes('regular')) {
        return acc;
      }

      // Mutate Googles' format to ours
      const font: Font = {
        key: `${googleFont.family}google`,
        label: googleFont.family.toLocaleLowerCase(),
        fontFamily: googleFont.family,
        variants: googleFont.variants,
        category: googleFont.category,
        files: googleFont.files,
      };

      // Directly add all google fonts to <Head />
      // const link = document.createElement('link');
      // link.rel = 'stylesheet';
      // link.href = `https://fonts.googleapis.com/css?family=${font.fontFamily}&subset=latin`;
      // document.head.appendChild(link);

      acc.push(font);
      return acc;
    }, [] as Font[]);

    // Fonts are loading async - we can't control the process with direct approach
    // If fonts are loaded after we use them - until we interact with page - they won't work
    // With extra second - everything will be loaded and fonts won't be broken when canvas loads
    await sleep(3000);
    return fonts;
  } catch (err) {
    const error = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});
