import { useCallback } from 'react';
import intersection from 'lodash/intersection';

import { useCurrentUser } from 'modules/auth/hooks/use-current-user';

export const usePermissions = () => {
  const { currentUser } = useCurrentUser();

  const hasPermission = useCallback(
    (permission: string | string[]) => {
      if (!Array.isArray(permission)) {
        return currentUser.permissions.includes(permission);
      }

      const intersectionPermissions = intersection(currentUser.permissions, permission);

      return !!intersectionPermissions.length;
    },
    [currentUser.permissions]
  );

  return { hasPermission };
};
