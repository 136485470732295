import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'core';

import publicContentAPI from '../api.service';
import { refreshDataAfterUpdate } from '../utils';

import { DeletePublicContentParams } from '../types';
import { IErrors } from 'core/types';
import { PublicContent, PublicContentsParams, PublicContentsType } from 'modules/common/types';

import commonPublicContentAPI from 'modules/common/api.services/public-content.api.service';

export const getTemplates = createAsyncThunk<
  PublicContent,
  Omit<PublicContentsParams, 'contentType'> | undefined,
  { rejectValue: IErrors }
>('manage/templates', async (payload, thunkApi) => {
  try {
    const response = await commonPublicContentAPI.getPublicContents({
      ...payload,
      contentType: PublicContentsType.Template,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const editTemplateTags = createAsyncThunk<
  void,
  PublicContentsParams,
  { rejectValue: IErrors; state: RootState }
>('manage/templates/edit/tags', async (payload, thunkApi) => {
  try {
    await publicContentAPI.editPublicContentTags({
      contentType: PublicContentsType.Template,
      ...payload,
    });

    // const refreshCb = (params: PublicContentsParams) => {
    //   thunkApi.dispatch(getTemplates(params));
    // };

    // refreshDataAfterUpdate({
    //   initialState: false,
    //   contentType: PublicContentsType.Template,
    //   store: thunkApi.getState(),
    //   refreshCb,
    // });
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const editBulkTemplateTags = createAsyncThunk<
  void,
  {
    params: PublicContentsParams[];
    onSuccess: () => void;
  },
  { rejectValue: IErrors; state: RootState }
>('manage/bulk/templates/edit/tags', async ({ params, onSuccess }, thunkApi) => {
  try {
    await publicContentAPI.editBulkPublicContentTags(params);

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getTemplates(params));
    };

    refreshDataAfterUpdate({
      initialState: false,
      contentType: PublicContentsType.Template,
      store: thunkApi.getState(),
      refreshCb,
    });

    onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteTemplate = createAsyncThunk<
  void,
  DeletePublicContentParams & { onSuccess: () => void },
  { rejectValue: IErrors; state: RootState }
>('manage/templates/delete', async (payload, thunkApi) => {
  try {
    await publicContentAPI.deletePublicContent({
      contentType: PublicContentsType.Template,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getTemplates(params));
    };

    refreshDataAfterUpdate({
      initialState: false,
      contentType: PublicContentsType.Template,
      store: thunkApi.getState(),
      refreshCb,
    });

    payload.onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const deleteBulkTemplates = createAsyncThunk<
  void,
  DeletePublicContentParams & { onSuccess: () => void },
  { rejectValue: IErrors; state: RootState }
>('manage/templates/delete/bulk', async (payload, thunkApi) => {
  try {
    await publicContentAPI.deleteBulkPublicContents({
      contentType: PublicContentsType.Template,
      ...payload,
    });

    const refreshCb = (params: PublicContentsParams) => {
      thunkApi.dispatch(getTemplates(params));
    };

    refreshDataAfterUpdate({
      contentType: PublicContentsType.Template,
      store: thunkApi.getState(),
      refreshCb,
    });

    payload.onSuccess();
  } catch (err) {
    const error = err as AxiosError<IErrors>;
    if (!error.response) {
      throw err;
    }
    return thunkApi.rejectWithValue(error.response.data);
  }
});
