import Auth from '@aws-amplify/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IUserInfo } from './types';

interface IErrors {
  errorMessage: string;
}

const getUserPermissions = async (): Promise<string[]> => {
  const aws = await Auth.currentSession();
  const idToken = aws.getIdToken();
  const idTokenPayload = idToken.decodePayload();

  return idTokenPayload['cognito:groups'] ?? [];
};

export const getUserInfo = createAsyncThunk<
  // Return type of the payload creator
  IUserInfo,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('user/get', async (id, thunkApi) => {
  try {
    const user = await Auth.currentUserInfo();
    const permissions = await getUserPermissions();

    return {
      username: user.username,
      permissions,
      ...user.attributes,
    };
  } catch (err) {
    const error = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getUserInfoShadow = createAsyncThunk<
  // Return type of the payload creator
  IUserInfo,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    rejectValue: IErrors;
  }
>('user/get/shadow', async (id, thunkApi) => {
  try {
    const user = await Auth.currentUserInfo();
    const permissions = await getUserPermissions();

    return {
      username: user.username,
      permissions,
      ...user.attributes,
    };
  } catch (err) {
    const error = err as AxiosError<IErrors>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error.response.data);
  }
});
